import axios from "axios";
import generateRequestSignature from "helpers/generateRequestSignature";
import { store } from "redux/store";

const API_URL = process.env.REACT_APP_BACKEND_URL;

const axiosConfig = axios.create({
  baseURL: API_URL || "https://staging-api.materialspro.ng/api/v1",
});
axiosConfig.interceptors.request.use(
  function (config) {
    const {
      auth: { token },
    } = store.getState();
    // if (!token) return Promise.reject("unAuthorized");
    const headers: any = {
      Authorization: "Bearer " + token,
      ...config.headers,
      "X-Signature": generateRequestSignature(
        String(config.method).toUpperCase(),
        `${API_URL}${config.url}`,
        !config.data ? "" : JSON.stringify({ ...config.data })
      ),
    };
    config.headers = headers!;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosConfig.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosConfig;
