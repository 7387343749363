import { useEffect, useState } from "react";
import { parse } from "fast-qs";
import styles from "./buildmate.module.css";
import PageSpinner from "components/PageSpinner";
import checkFilled from "../../assets/success-check.png";
import declined from "../../assets/close.png";
import axiosConfig from "config/axios";
import useGodumpApi from "hooks/useGodumpApi";
import { useLocation } from "react-router";
import requestErrorHandler from "utils/requestErrorHandler";
// import axiosConfig from "config/axios";

export default function VerifyBuildMatePayment() {
  const { search } = useLocation();

  const { createodumpOrder } = useGodumpApi();

  const { reference } = parse(search) as {
    reference: string;
  };
  const [token, setToken] = useState<string>("");
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);

  // https://staging-api.materialspro.ng/api/v1/order/${reference}
  const confirmPayment = async () => {
    setIsConfirming(true);
    await axiosConfig
      .post(
        `homeowner/verify-payment`,
        { reference },
        { headers: { Authorization: "Bearer " + token } }
      )
      .then(async (res) => {
        const { data } = await axiosConfig.get(
          `homeowner/get-order-by-payment-reference?reference=${reference}`
        );
        const deliveredByGodump = data?.order?.order_items.find(
          (item: any) => item?.deliverdBygodump
        );
        return { id: data.order.id, deliveredByGodump };
      })
      .then(async (res) => {
        if (res.deliveredByGodump !== null) {
          await createodumpOrder(res.id);
          setIsSuccessful(true);
        } else {
          setIsSuccessful(true);
        }
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => setIsConfirming(false));
  };

  let runOnce = true;
  useEffect(() => {
    if (runOnce === true) {
      runOnce = false;
      confirmPayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const _token: string = localStorage.getItem("token")?.toString()!;
    setToken(_token);
  }, []);

  if (isConfirming) {
    return <PageSpinner fullScreen title="Verifying payment..." />;
  }

  return (
    <div>
      <div className={styles.payment}>
        {isSuccessful ? (
          <article>
            <h1>PAYMENT SUCCESSFUL</h1>
            <img src={checkFilled} alt="Payment successful" width="100" />
          </article>
        ) : (
          <article>
            <h1 style={{ color: "#FF0000" }}>PAYMENT FAILED</h1>
            <img src={declined} alt="Payment failed" width="100" />
          </article>
        )}
      </div>
    </div>
  );
}
