import { useEffect, useState } from "react";
import requestErrorHandler from "utils/requestErrorHandler";
import { parse } from "fast-qs";
import axiosConfig from "config/axios";
import { useLocation } from "react-router";

const usePayment = () => {
  const [paystackUrl, setPaystackUrl] = useState<string>();
  const [processing, setProcessing] = useState<boolean>(false);

  const { search } = useLocation();

  const { orderId, token } = parse(search) as {
    orderId: string;
    token: string;
  };

  const handlePayNow = async () => {
    setProcessing(true);
    try {
      const {
        data: {
          data: { data },
        },
      } = await axiosConfig.post(
        `homeowner/pay`,
        { orderId },
        { headers: { Authorization: "Bearer " + token } }
      );
      setPaystackUrl(data?.authorization_url);
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (!paystackUrl) return;
    window.location.href = `${paystackUrl}`;
  }, [paystackUrl]);

  return {
    handlePayNow,
    processing,
  };
};

export default usePayment;
